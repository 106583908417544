import { css } from 'lit'

export const newStyles = css`
  :host {
    --tml-language-picker-width: 64px;
    --tml-language-picker-height: 37px;
    --tml-language-picker-color-background-button: hsla(0, 100%, 100%, 0.1);
    --tml-language-picker-color-background: hsla(0, 100%, 100%, 0.1);
    --tml-language-picker-color-button: hsla(0, 100%, 100%, 1);
    --tml-language-picker-color: hsla(0, 100%, 100%, 0.6);
    --tml-language-picker-color-hover: hsla(0, 100%, 100%, 1);

    z-index: 9960;
    display: block;
    width: var(--tml-language-picker-width);
  }

  * {
    box-sizing: border-box;
  }

  span,
  button {
    font-size: 12px;
    font-weight: 700;
    font-family: europa, sans-serif;
    letter-spacing: 1.2px;
  }

  button {
    width: 100%;
    height: var(--tml-language-picker-height);
    text-transform: uppercase;
    color: var(--tml-language-picker-color-button);
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  ul {
    flex: 0 1 auto;
    display: flex;
    margin: 0;
    padding: 0;
  }

  span {
    pointer-events: none;
  }

  .mobile-picker {
    z-index: 2;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 0;
    color: hsla(0, 100%, 100%, 1);
  }

  .mobile-picker button {
    width: fit-content;
    padding-inline: 16px;
    padding-block: 0;
    font-size: 14px;
    text-transform: uppercase;
  }

  .mobile-picker button:hover {
    text-decoration: underline;
  }

  .mobile-picker button + button {
    border-left: 1px solid hsla(0, 100%, 100%, 1);
  }

  .caret {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    top: -1px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='666 518 11 11'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M671.75 525.76l3.28-3.28.57-.57 1.13 1.13-.56.57-3.85 3.85-.57.56-.28-.28-.29-.28-3.85-3.86-.56-.56 1.13-1.13.56.56 3.29 3.29z'/%3E%3C/svg%3E");
    pointer-events: none;
  }

  li {
    list-style: none;
    white-space: nowrap;
  }

  .picker-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: var(--tml-language-picker-width);
    padding: 0px 14px;
    text-align: end;
    background-color: var(--tml-language-picker-color-background-button);
    border-radius: 9999px;
    border: 1px solid transparent;
    transition: border 0.3s ease-in-out;
  }

  .picker-button:hover,
  .picker-open {
    border: 1px solid hsla(0, 100%, 100%, 1);
  }

  .language-picker {
    z-index: 9960;
    position: absolute;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: var(--tml-language-picker-width);
    padding: 0 10px;
    opacity: 0;
    border-radius: 16px;
    border: 1px solid var(--tml-language-picker-color-background);
    background: var(--tml-language-picker-color-background);
    backdrop-filter: brightness(60%) blur(100px);
    -webkit-backdrop-filter: brightness(60%) blur(100px);
    box-shadow: 0 15px 70px 0 rgb(0 0 0 / 50%);
    transition: opacity 0.3s ease;
    pointer-events: none;
    overflow: hidden;
  }

  .language-picker.open {
    opacity: 1;
    pointer-events: auto;
  }

  .language-picker > li:last-child button {
    border-bottom: 0;
  }

  .language-picker button {
    border-bottom: 1px solid hsla(0, 100%, 100%, 0.1);
    text-transform: uppercase;
    height: 60px;
    line-height: 60px;
  }

  .language-picker button {
    color: var(--tml-language-picker-color);
  }

  .language-picker button:hover {
    color: var(--tml-language-picker-color-hover);
  }
`
