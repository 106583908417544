import { LitElement, html, nothing } from 'lit'
import { property, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { map } from 'lit/directives/map.js'
import { getCookie, getISOLocale, getQueryParam, setCookie } from '../../utils'
import { styles } from './styles'
import { newStyles } from './new-styles'
import {
  TmlLanguagePickerDocumentData,
  TmlLanguagePickerDocumentDataAvailableLanguagesItem,
} from '@repo/prismic-data/prismicio-types'
import { booleanConverter } from '../../utils/converters'

declare global {
  interface HTMLElementTagNameMap {
    'tml-language-picker': TMLLanguagePicker
  }
}

const ONE_YEAR = 365

// NOTE: This is outside of the web component and just part of JS
// Detect click outside of our web-component
document.addEventListener('click', () => {
  const languagePickers = Array.from(
    document.getElementsByTagName('tml-language-picker'),
  )

  languagePickers?.forEach((picker) => picker.outsideClick())
})

document.addEventListener('DOMContentLoaded', () => {
  const languagePickers = Array.from(
    document.getElementsByTagName('tml-language-picker'),
  )

  languagePickers?.forEach((picker) => {
    picker.addEventListener('languageChange', ((
      event: CustomEvent<{ language: string; previousLanguage: string }>,
    ) => {
      languagePickers.forEach((otherPicker) =>
        otherPicker.setLanguage(event.detail.language),
      )
    }) as EventListener)
  })
})

export class TMLLanguagePicker extends LitElement {
  @state()
  private _isLanguagePickerOpen?: boolean

  @property({ converter: booleanConverter })
  new?: boolean

  @property({ converter: booleanConverter })
  mobile?: boolean

  @property()
  language?: string

  @property()
  languages?: string

  @property()
  availableLanguages?: TmlLanguagePickerDocumentData['available_languages']

  @property({
    attribute: false,
  })
  data: Record<string, TmlLanguagePickerDocumentData> | undefined

  constructor() {
    super()

    const data = this.data?.[this.language || 'en-us']

    if (data) {
      this.availableLanguages = data.available_languages

      // Check for query param
      const queryLanguage = getQueryParam('lang') ?? getQueryParam('language')

      if (queryLanguage) {
        this._setLanguage(queryLanguage)
        return
      }

      // Check for path param
      const pathLanguage = this._getPathParam(data.available_languages)

      if (pathLanguage) {
        setCookie('tml-language-picker', pathLanguage, ONE_YEAR)
        this._setLanguage(pathLanguage)
        return
      }

      // Check for cookie
      const cookieLanguage = getCookie('tml-language-picker')

      if (cookieLanguage) {
        this._setLanguage(cookieLanguage)
        return
      }

      // Set default language
      const defaultLanguage = data.available_languages.find(
        (language: TmlLanguagePickerDocumentDataAvailableLanguagesItem) =>
          language.is_default,
      )

      if (defaultLanguage) {
        this._setLanguage(defaultLanguage.language ?? '')
      } else {
        this._setLanguage(data.available_languages[0]?.language ?? '')
      }
    }
  }

  setLanguage(language: string) {
    const foundLanguage = this.availableLanguages?.find(
      (
        availableLanguage: TmlLanguagePickerDocumentDataAvailableLanguagesItem,
      ) => availableLanguage.language?.includes(language),
    )?.language

    const newLanguage =
      foundLanguage ??
      this.availableLanguages?.find(
        (language: TmlLanguagePickerDocumentDataAvailableLanguagesItem) =>
          language.is_default,
      )?.language

    if (newLanguage) {
      this._setLanguage(newLanguage, false)

      // If the site directly sets this language we assume we want to keep it this language
      setCookie('tml-language-picker', newLanguage, ONE_YEAR)
    }
  }

  outsideClick() {
    this._isLanguagePickerOpen = false
  }

  private _getPathParam(
    languages: TmlLanguagePickerDocumentData['available_languages'],
  ) {
    const pathParams = window.location.pathname.split('/')

    for (const key in pathParams) {
      const foundLanguage = languages?.find(
        (
          availableLanguage: TmlLanguagePickerDocumentDataAvailableLanguagesItem,
        ) =>
          getISOLocale(pathParams[key]) &&
          getISOLocale(availableLanguage.language ?? undefined) ===
            getISOLocale(pathParams[key]),
      )?.language

      if (foundLanguage) {
        return foundLanguage
      }
    }

    return undefined
  }

  private _setLanguage(language: string, withEvent = true) {
    const previousLanguage = this.language
    this.language = language

    // Update document language
    document.documentElement.setAttribute('lang', getISOLocale(language) ?? '')

    // Update other components
    const componentScripts = document.querySelectorAll<HTMLScriptElement>(
      `script[src*='/tml-topbar/'], script[src*='/tml-footer/'], script[src*='/tml-language-picker/']`,
    )

    componentScripts?.forEach((componentScript) => {
      // Remove the old script it
      componentScript.parentNode?.removeChild(componentScript)

      // Add a new one with the other locale
      const newComponentScript = document.createElement('script')
      newComponentScript.type = 'module'
      newComponentScript.src = componentScript.src
        .replace('en-us', language)
        .replace('pt-br', language)

      if (newComponentScript.src.includes('tml-topbar')) {
        newComponentScript.onload = () => {
          document
            .getElementsByTagName('tml-topbar')?.[0]
            ?.loadLanguage(language)
        }
      }

      if (newComponentScript.src.includes('tml-footer')) {
        newComponentScript.onload = () => {
          document
            .getElementsByTagName('tml-footer')?.[0]
            ?.loadLanguage(language)
        }
      }

      document.head.appendChild(newComponentScript)
    })

    if (withEvent) {
      this.dispatchEvent(
        new CustomEvent('languageChange', {
          detail: { language, previousLanguage },
          bubbles: true,
          composed: true,
        }),
      )
    }
  }

  private _showLanguagePickerClickHandler(event: Event) {
    event.stopPropagation()
    document.querySelector('tml-topbar')?.outsideClick()

    this._isLanguagePickerOpen = !this._isLanguagePickerOpen
  }

  private _languageClickHandler(event: Event, language: string) {
    event.stopPropagation()

    this._setLanguage(language)

    this._isLanguagePickerOpen = false
  }

  renderLanguagePicker(
    languages: TmlLanguagePickerDocumentData['available_languages'],
  ) {
    const allowedLanguages = this.languages?.split(',') ?? undefined
    const filteredLanguages = allowedLanguages
      ? ((languages.filter(
          (language: TmlLanguagePickerDocumentDataAvailableLanguagesItem) =>
            allowedLanguages.includes(language.language ?? ''),
        ) ?? []) as TmlLanguagePickerDocumentData['available_languages'])
      : languages

    return html`
      <ul
        class=${classMap({
          'language-picker': true,
          open: !!this._isLanguagePickerOpen,
        })}
      >
        ${map(filteredLanguages, (language) => {
          if (!language.language) {
            return nothing
          }

          return html`<li>
            <button
              @click=${(event: Event) =>
                this._languageClickHandler(event, language.language ?? '')}
            >
              <span>${language.label}</span>
            </button>
          </li>`
        })}
      </ul>
    `
  }

  renderMobileLanguagePicker(
    languages: TmlLanguagePickerDocumentData['available_languages'],
  ) {
    const allowedLanguages = this.languages?.split(',') ?? undefined
    const filteredLanguages = allowedLanguages
      ? ((languages.filter(
          (language: TmlLanguagePickerDocumentDataAvailableLanguagesItem) =>
            allowedLanguages.includes(language.language ?? ''),
        ) ?? []) as TmlLanguagePickerDocumentData['available_languages'])
      : languages

    return html`
      ${map(filteredLanguages, (language) => {
        if (!language.language) {
          return nothing
        }

        return html`
          <button
            @click=${(event: Event) =>
              this._languageClickHandler(event, language.language ?? '')}
          >
            ${language.label}
          </button>
        `
      })}
    `
  }

  render() {
    const data = this.data?.[this.language || 'en-us']
    if (!data) return nothing
    return html`
      <style>
            ${this.new ? newStyles : styles}
      </style>
      ${this.mobile
        ? html`<div class="mobile-picker">
            ${this.renderMobileLanguagePicker(data.available_languages)}
          </div> `
        : html`
            <button
              class=${classMap({
                'picker-button': true,
                'picker-open': !!this._isLanguagePickerOpen,
              })}
              aria-haspopup="true"
              aria-expanded=${this._isLanguagePickerOpen}
              @click=${this._showLanguagePickerClickHandler}
            >
              <span>${this.language?.split('-')[0]}</span>
              <span class="caret"></span>
            </button>
            ${this.renderLanguagePicker(data.available_languages)}
          `}
    `
  }
}

// Define only if not already exists
if (!window.customElements.get('tml-language-picker')) {
  window.customElements.define('tml-language-picker', TMLLanguagePicker)
}
